import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="drawer"
export default class extends Controller {
  static targets = ["content", "backdrop", "drawer"];

  close() {
    this.hide();
  }

  show() {
    this.backdropTarget.classList.remove("opacity-0");
    this.backdropTarget.classList.add("opacity-backdrop");
    this.drawerTarget.classList.remove("translate-x-full");
  }

  hide() {
    this.backdropTarget.classList.remove("opacity-backdrop");
    this.backdropTarget.classList.add("opacity-0");
    this.drawerTarget.classList.add("translate-x-full");
    setTimeout(() => this.contentTarget.remove(), 500); // wait for the transition to finish before removing the element
  }
}
