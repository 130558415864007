import { Controller } from "@hotwired/stimulus";

export default class Dialog extends Controller {
  static targets = ["dialog"];

  static values = {
    open: Boolean,
    id: String
  };

  initialize() {
    this.forceClose = this.forceClose.bind(this);
  }

  connect() {
    this.updateVisibility();

    document.addEventListener("turbo:before-render", this.forceClose);
    document.addEventListener("dialog:open", this.handleOpen.bind(this));
    document.addEventListener("dialog:close", this.handleClose.bind(this));
  }

  disconnect() {
    document.removeEventListener("turbo:before-render", this.forceClose);
    document.removeEventListener("dialog:open", this.handleOpen.bind(this));
    document.removeEventListener("dialog:close", this.handleClose.bind(this));
  }

  updateVisibility() {
    if (this.openValue) {
      this.open();
    }
  }

  handleOpen(event) {
    if (event.detail.id === this.idValue) {
      this.open();
    }
  }

  handleClose(event) {
    if (event.detail.id === this.idValue) {
      this.close();
    }
  }

  open() {
    this.dialogTarget.showModal();
  }

  close() {
    this.dialogTarget.setAttribute("closing", "");

    Promise.all(
      this.dialogTarget.getAnimations().map(animation => animation.finished)
    )
      .then(() => {
        this.dialogTarget.removeAttribute("closing");
        this.dialogTarget.close();
      })
      .catch(() => {
        this.dialogTarget.removeAttribute("closing");
        this.dialogTarget.close();
      });
  }

  backdropClose(event) {
    if (event.target === this.dialogTarget) {
      this.close();
    }
  }

  forceClose() {
    this.dialogTarget.close();
  }
}
