import DrawerController from "./drawer_controller";

// Connects to data-controller="collection-drawer"
export default class extends DrawerController {
  connect() {
    super.connect(); // Ensures that base connect logic is executed
    this.element.addEventListener(
      "turbo:frame-load",
      this.handleFrameLoad.bind(this)
    );
  }

  disconnect() {
    this.element.removeEventListener(
      "turbo:frame-load",
      this.handleFrameLoad.bind(this)
    );
  }

  handleFrameLoad() {
    window.resetFavourites();
    this.show();
  }
}
