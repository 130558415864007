import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["content"];

  static values = {
    showOnConnect: Boolean,
    removeOnHide: Boolean
  };

  connect() {
    if (this.showOnConnectValue) {
      this.show();
    }
  }

  show() {
    enter(this.element);
    enter(this.contentTarget);
  }

  hide() {
    leave(this.contentTarget);
    leave(this.element).then(() => {
      if (this.removeOnHideValue) {
        this.element.remove();
      }
    });
  }
}
