import { Controller } from "@hotwired/stimulus";
import Cookies from "js-cookie";

// Connects to data-controller="cookie"
export default class extends Controller {
  static values = {
    name: String,
    value: String,
    expiration: Number
  };

  set() {
    Cookies.set(this.nameValue, this.valueValue, {
      expires: this.expirationValue
    });
  }
}
