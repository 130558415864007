import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="change-store"
export default class extends Controller {
  static values = {
    name: String,
    value: String,
    expiration: Number,
    rootUrl: String
  };

  static targets = ["storeLink", "selectedStore", "storeRootUrl"];

  connect() {
    this.state = {
      hrefLangLookup: {}
    };

    this.createHrefLookUp();

    this.storeLinkTargets.forEach(link => {
      const matchingLocale = this.state.hrefLangLookup[link.dataset.locale];

      if (matchingLocale) {
        link.href = matchingLocale;
      }
    });
  }

  updateStoreUrl() {
    const selectedOption =
      this.selectedStoreTarget.options[this.selectedStoreTarget.selectedIndex];
    const matchingLocale =
      this.state.hrefLangLookup[selectedOption.getAttribute("locale")];

    if (matchingLocale) {
      this.storeRootUrlTarget.value = matchingLocale;
    } else {
      this.storeRootUrlTarget.value = selectedOption.getAttribute("url");
    }
  }

  createHrefLookUp() {
    const links = document.querySelectorAll('link[rel="alternate"]');

    const linksArray = Array.from(links);

    const lookUpObject = linksArray.reduce((obj, link) => {
      obj[link.hreflang] = link.href;
      return obj;
    }, {});

    this.state.hrefLangLookup = lookUpObject;
  }
}
