import { Controller } from "@hotwired/stimulus";
import { leave, toggle } from "el-transition";

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = ["menu"];

  toggle() {
    toggle(this.menuTarget);
  }

  hide(event) {
    if (
      !this.element.contains(event.target) &&
      !this.menuTarget.classList.contains("hidden")
    ) {
      leave(this.menuTarget);
    }
  }
}
