import { Controller } from "@hotwired/stimulus";
import { leave } from "el-transition";

// Connects to data-controller="popup"
export default class extends Controller {
  close() {
    leave(this.element).then(() => {
      this.element.remove();
    });
  }
}
