import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="flash-message"
export default class extends Controller {
  connect() {
    setTimeout(() => {
      this.showFlashMessage();
    }, 500);
    setTimeout(() => {
      this.hideFlashMessage();
    }, 5500);
  }

  showFlashMessage() {
    this.element.classList.add("in");
  }

  hideFlashMessage() {
    this.element.classList.remove("in");
  }
}
