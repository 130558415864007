import { Controller } from "@hotwired/stimulus";
import favouritesApi from "@/app/javascript/api/favourite";

const isFavourited = ({ type, id }) => {
  return !!window.favouritedListings.find(favourite => {
    return favourite[0] === type && favourite[1] === parseInt(id, 10);
  });
};

// Connects to data-controller="favourites"
export default class extends Controller {
  static targets = ["faveheart"];

  async favouriteAll() {
    const ids = this.faveheartTargets.map(faveheart => faveheart.dataset.id);
    const { type } = this.faveheartTargets[0].dataset;

    await favouritesApi.batchAdd(ids, type);

    this.faveheartTargets.forEach(faveheart => {
      const { id, analyticsId } = faveheart.dataset;

      if (isFavourited({ type, id })) return;

      window.favouritedListings.push([type, parseInt(id, 10)]);

      analytics.track("Product Added to Wishlist", {
        product_id: analyticsId,
        sku: analyticsId
      });
    });

    window.dispatchEvent(new CustomEvent("addedFavourite"));

    window.resetFavourites();
  }
}
