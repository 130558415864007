import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["searchType"];

  search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (this.searchTypeTarget.value === "autocomplete") {
        this.element.requestSubmit();
      }
    }, 200);
  }

  detectSubmit(event) {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submission
      this.searchTypeTarget.value = "full_search";
      this.element.dataset.turbo = "false";
      this.element.requestSubmit();
    }
  }
}
