import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="show-password"
export default class extends Controller {
  static targets = ["input", "toggle"];

  toggle() {
    if (this.inputTarget.type === "password") {
      this.inputTarget.type = "text";
      this.toggleTarget.textContent = "Hide password";
    } else {
      this.inputTarget.type = "password";
      this.toggleTarget.textContent = "Show password";
    }
  }
}
